import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Nav = styled.nav`
  display: flex;
  ul {
    display: flex;
    flex-direction: horizontal;
    align-items: center;
    margin: 0;
    padding-left: 0;
    padding-right: 1.8rem;
    li {
      margin-right: 2rem;
      a {
        font-size: 18px;
        text-decoration: none;
        padding: 15px 0 5px;
      }
    }
    li:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 700px) {
    ul {
      padding-right: 10px;
      li {
        margin-right: 20px;
      }
    }
  }
`

const Navigation = ({ menuLinks }) => {
  return (
    <Nav>
      <ul>
        {menuLinks.map(link => (
          <li
            key={link.name}
            style={{
              listStyleType: `none`,
            }}
          >
            <Link
              to={link.link}
              partiallyActive={true}
              activeStyle={{
                borderBottom: "2px solid",
              }}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </Nav>
  )
}

export default Navigation
