import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Container = styled.h1`
  display: flex;
  align-items: center;
  a {
    font-size: 24px;
    text-transform: lowercase;
  }
  margin: 0;

  @media (max-width: 700px) {
    a {
      font-size: 20px;
    }
  }
`

const Title = ({ title }) => {
  return (
    <Container>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        {title}
      </Link>
    </Container>
  )
}
export default Title
