import React from "react"
import styled from "@emotion/styled"

const Container = styled.footer`
  height: 120px;
  max-width: 1420px;
  margin: 0 auto;
  padding: 0 75px;

  @media (max-width: 700px) {
    height: 80px !important;
    padding: 0px 20px;
  }

  font-size: 0.8rem;
  display: flex;
  align-items: center;

  color: ${props => props.theme.fontColor};
`

const Footer = ({ author }) => {
  return (
    <Container>
      <div>
        © {new Date().getFullYear()} {author}
      </div>
    </Container>
  )
}

export default Footer
