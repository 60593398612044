/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, title, image, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            titleAlt
            shortName
            author
            keywords
            siteUrl: url
            siteLanguage
            defaultDescription: description
            twitter
          }
        }
      }
    `
  )

  const seo = {
    description: description || site.siteMetadata.defaultDescription,
    title: site.siteMetadata.defaultTitle,
    image: image || ``,
    url: `${site.siteMetadata.siteUrl}${pathname ? pathname : "/"}`,
    keywords: site.siteMetadata.keywords,
    twitter: site.siteMetadata.twitter,
  }

  return (
    <Helmet
      defer={false}
      title={title}
      titleTemplate={`%s | ${seo.title}`}
      defaultTitle={site.siteMetadata.defaultTitle}
    >
      <meta lang={site.siteMetadata.siteLanguage} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <link rel="canonical" href={seo.url} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta
        name="keywords"
        content={
          seo.keywords && seo.keywords.length > 0 ? seo.keywords.join(`, `) : ""
        }
      />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.twitter && <meta name="twitter:creator" content={seo.twitter} />}
      {seo.twitter && <meta name="twitter:site" content={seo.twitter} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO
