import React from "react"
import Wrapper from "./wrapper"
import Title from "./title"
import Navigation from "./navigation"
import LightDarkSwitcher from "./lightDarkSwitcher"

const Header = ({ children, collapsableOnScroll, sticky }) => {
  return (
    <Wrapper collapsableOnScroll={collapsableOnScroll} sticky={sticky}>
      {children}
    </Wrapper>
  )
}

const DefaultHeader = props => {
  return (
    <Header
      sticky={props.sticky}
      collapsableOnScroll={props.collapsableOnScroll}
    >
      <div>
        <Title title={props.siteTitle} />
      </div>
      <div>
        <Navigation menuLinks={props.menuLinks} />
        <LightDarkSwitcher />
      </div>
    </Header>
  )
}

export default DefaultHeader
