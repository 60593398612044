import styled from "@emotion/styled"

const LinkButton = styled.button`
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  svg {
    height: 16px;
    width: 16px;
    .a {
      fill: ${props => props.theme.fontColor};
    }
    pointer-events: none;
  }
  > * {
    margin-left: auto;
    margin-right: auto;
  }
  // &:hover {
  //   background-color: ${props => props.theme.foregroundColor};
  // }

  @media (max-width: 700px) {
    svg {
      height: 14px;
      width: 14px;
    }
  }
`

export default LinkButton
