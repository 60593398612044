import React, { useMemo } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import useScrollCounter from "../../hooks/useScrollCounter"
import HeaderContext from "./context"

const HeaderWrapper = styled(motion.div)`
  @media (max-width: 700px) {
    height: 80px !important;
    box-shadow: ${props =>
      props.sticky ? props.theme.headerBoxShadow : "none"};
  }

  @media (max-width: 900px) and (max-height: 400px) {
    height: 80px !important;
    box-shadow: ${props =>
      props.sticky ? props.theme.headerBoxShadow : "none"};
  }

  transition: background ${props => props.theme.transitionTime}s;
  background: ${props => props.theme.backgroundColor};
  width: 100%;
  position: ${props => (props.sticky ? "fixed" : "inherit")};
  z-index: 999;
  ${props =>
    props.slim
      ? `box-shadow: ${props.theme.headerBoxShadow}; backdrop-filter: blur(6px); opacity: 0.88;`
      : ""}
`

const HeaderContent = styled.div`
  @media (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  padding-left: 70px;
  padding-right: 70px;
  margin: 0 auto;
  height: inherit;
  max-width: 1420px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
  }
  a {
    color: ${props => props.theme.fontColor};
  
`

const Wrapper = props => {
  const collapsed = useScrollCounter(props.collapseOffset || 150)
  const shouldCollapse = props.collapsableOnScroll ? collapsed : false

  const memoizedContextValue = useMemo(
    () => ({
      collapsed: shouldCollapse,
      sticky: props.sticky || false,
    }),
    [props.sticky, shouldCollapse]
  )

  const variants = {
    open: {
      height: 120,
    },
    collapsed: { height: 70 },
  }

  return (
    <HeaderContext.Provider value={memoizedContextValue}>
      <HeaderWrapper
        sticky={props.sticky}
        slim={shouldCollapse}
        initial={"open"}
        animate={shouldCollapse ? "collapsed" : "open"}
        variants={variants}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        <HeaderContent>{props.children}</HeaderContent>
      </HeaderWrapper>
    </HeaderContext.Provider>
  )
}

export default Wrapper
