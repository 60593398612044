import React from "react"
import { useTheme } from "../../context/themeContext"
import LinkButton from "./linkButton"
import LightIcon from "../../assets/light.svg"
import MoonIcon from "../../assets/moon.svg"

const LightDarkSwitcher = () => {
  const theme = useTheme()

  return (
    <LinkButton
      onClick={e => {
        theme.toggleDark()
        e.stopPropagation()
      }}
      aria-label={theme.dark ? "Activate light mode" : "Activate dark mode"}
      title={theme.dark ? "Activate light mode" : "Activate dark mode"}
    >
      {theme.dark ? <LightIcon /> : <MoonIcon />}
    </LinkButton>
  )
}

export default LightDarkSwitcher
